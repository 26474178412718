<template>

  <v-container>
    <v-row justify="center">
      <v-col align="center" md="4">
        <v-text-field
          v-model="name"
          :label="$t('initiate.Shift')"  
          outlined
          dense
          class="mt-5"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row justify="center" class="mt-6">
      <v-col md="7">
        <v-data-table :dark="$store.state.isDarkMode"
          :headers="headers"
          :items="schedule"
          item-key="day"
          style="border: 1px solid #ccc"
          hide-default-footer
        >
          <template v-slot:headers>
            <thead>
              <tr>
              
                <th v-for="header in headers" :key="header.value">
                  {{ header.text }}
                </th>
                
              </tr>
            </thead>
          </template>
          <template v-slot:body="{ items, headers }">
            <tbody>
              <tr v-for="(item, idx) in items" :key="idx">
                <td>
                  <v-checkbox
                  class="mx-4 mb-4"
                  v-model="item.selected"
                  hide-details
                ></v-checkbox>
                </td>
                <td v-for="(header, key) in headers" :key="key">
                  <v-edit-dialog
                    :return-value.sync="item[header.value]"
                    @save="save"
                    @cancel="cancel"
                    @open="open"
                    @close="close"
                    large
                  >
                    {{ item[header.value] }}
                    <template v-slot:input>
                      <v-text-field
                        v-model="item[header.value]"
                        label="Edit"
                        single-line
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row justify="center" class="mt-6">
      <v-col cols="2">
        <v-btn color="primary" class="save-button" @click="submit">{{
          $t("save")
        }}</v-btn>
      </v-col>
      <v-col cols="2">
        <v-btn @click="cancel" color="error" class="cancel-button">{{
          $t("cancel")
        }}</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from "axios";
import i18n from '@/i18n';

export default {
  created() {
    if (this.$route.params.id != "CreateNewShift") {
      axios.get(`/hr/shift/${this.$route.params.id}`).then((res) => {
        console.log(res);
        this.name = res.data.data.name;
        for (let i = 0; i < res.data.data.shift_days.length; i++) {
          for (let j = 0; j < this.schedule.length; j++) {
            if (res.data.data.shift_days[i].day == this.schedule[j].day) {
              this.schedule[j].selected = true;
              this.schedule[j].time_in = res.data.data.shift_days[i].in_hour;
              this.schedule[j].time_out = res.data.data.shift_days[i].out_hour;
            }
          }
        }
      });
    }
  },
  data() {
    return {
      name: "",
      headers: [
        { text:  i18n.t('initiate.Day'), value: "day" },
        { text: i18n.t('hr.time.timeIn'), value: "time_in" },
        { text: i18n.t('hr.time.timeOut'), value: "time_out" },
      ],
      schedule: [
        {
          day: "Saturday",
          time_in: "09:00",
          time_out: "15:00",
          selected: false,
        },
        {
          day: "Sunday",
          time_in: "09:00",
          time_out: "15:00",
          selected: false,
        },
        {
          day: "Monday",
          time_in: "09:00",
          time_out: "15:00",
          selected: false,
        },
        {
          day: "Tuesday",
          time_in: "09:00",
          time_out: "15:00",
          selected: false,
        },
        {
          day: "Wednesday",
          time_in: "09:00",
          time_out: "15:00",
          selected: false,
        },

        {
          day: "Thursday",
          time_in: "09:00",
          time_out: "15:00",
          selected: false,
        },
        {
          day: "Friday",
          time_in: "09:00",
          time_out: "15:00",
          selected: false,
        },
      ],
      selectAll: false,
    };
  },
  methods: {
    
    cancel() {
      this.$router.push("/HR/initiate");
    },
    submit() {
      var shift_days = [];
      for (let i = 0; i < this.schedule.length; i++) {
        if (this.schedule[i].selected == true) {
          shift_days.push({
            in_hour: this.schedule[i].time_in,
            out_hour: this.schedule[i].time_out,
            day: this.schedule[i].day,
          });
        }
      }
      console.log(shift_days);
      if (this.$route.params.id == "CreateNewShift") {
        axios
          .post("/hr/shift", {
            name: this.name,
            days: shift_days,
          })
          .then((res) => {
            if (res.status == 200) {
              this.$router.go(-1);
              this.$Notifications(
                "تمت اضافة وردية جديدة بنجاح",
                { rtl: true, timeout: 2500 },
                "success"
              );
              this.name = "";
            }
          });
      } else {
        axios
          .put(`/hr/shift/${this.$route.params.id}`, {
            name: this.name,
            days: shift_days,
          })
          .then((res) => {
            if (res.status == 200) {
              this.$router.go(-1);
              this.$Notifications(
                "تم تعديل وردية  بنجاح",
                { rtl: true, timeout: 2500 },
                "success"
              );
              this.name = "";
            }
          });
      }
    },
    save() {},
    open() {},
    close() {},
  },
};
</script>
<style>
.custom-table .v-data-table__headers {
  /* Apply margin to the table headers */
  margin: 0 10px;
}

.custom-header {
  /* Apply right margin to the table header cells */
  margin-right: 50px;

  /* Optionally, you can apply additional styles to the table header cells */
  /* For example, you can change the background color or add padding */
  background-color: #f0f0f0;
  padding: 10px;
}
</style>